<template>
<div>
    <div class="pb-3">
        <TheHeader />
        
    </div>

    <div class="bg-image pt-5">
        <CRow>
            <CCol class="d-sm-down-none" md="6">
                <div class="heading-margin ">
                    <h1 class="heading-font-size">Lorem Ipsum is simply<br>
                        dummy text and<br>
                        <b class="heading-txt-color">Typesetting industry.</b><br>
                        Lorem Ipsum has been</h1>
                </div>
            </CCol>
            <CCol md="6">

                <CCard class="card-margin">
                    <CRow>
                        <CCol md="12">
                            <div class="p-5">
                                <h1 class="txt-color text-center pt-2 ">Search Restaurant</h1>

                                <CSelect class="pt-5" size="lg" placeholder="Select Location" :options="['Colombo','Matara']"></CSelect>
                                <CSelect class=" mt-5 " size="lg" placeholder="Select Category" :options="['Category 01','Category 02']"></CSelect>
                                <CInput class=" mt-5 " placeholder="Search in Name" size="lg"> </CInput>
                                <CButton color="primary mt-5 mb-3 btn-size" size="lg" block> Search </CButton>

                            </div>
                        </CCol>
                    </CRow>
                </CCard>

            </CCol>
        </CRow>
    </div>
    <CCard>

        <RestaurantCard />

    </CCard>
</div>
</template>

<script>
import TheHeader from '../../../containers/TheHeader.vue'
import RestaurantCard from "@/component/RestaurantCard/RestaurantCard.vue"

export default {
    name: 'Page500',
    components: {
        TheHeader,
        RestaurantCard,
    }
}
</script>

<style scoped>
.card-margin {
    margin: 5rem !important;
}

.bg-image {
    background-image: url('../../../../public/img/bg_images/bg_image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.heading-margin {
    padding-top: 25vh;
    padding-left: 5vw;
    
}
.heading-font-size {
    font-size: 3.2rem;
}

.heading-txt-color {
    color: #BE70FD;
    font-size: 4rem;
}

.btn-size {
    height: 54px;
}

.txt-color {
    color: #131212;
}

@media (max-width: 768px) {

    .card-margin {
        margin: 1rem !important;
        background-color: #ffffff67;
    }

    .txt-color {
    color: #ffffff;
}

}
</style>
